<main role="main" id="main" class="main-wrapper">
	<div class="container">
		<div class="row">
	    	<div class="col-lg-12 text-right mb-3">
	    		<a href="javascript:void(0)" routerLink="/admin/create-gallery" class="btn btn-primary col-2 mr-2">Create New Gallery</a>
	    		<!-- <a href="javascript:void(0)" routerLink="/admin/dashboard" class="btn btn-outline-primary col-2">Back</a> -->
				<!--<div ngbDropdown class="d-inline-block">
				<button class="btn btn-primary" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-filter"></i></button>
				<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
					<button ngbDropdownItem (click)="sortGallery('atoz')">A To Z</button>
					<button ngbDropdownItem (click)="sortGallery('ztoa')">Z To A</button>
					<button ngbDropdownItem (click)="sortGallery('latest')">Latest</button>
					<button ngbDropdownItem (click)="sortGallery('oldest')">Oldest</button>
				</div>
				</div>-->
	    	</div>
	    </div>

  		<div class="row gallery-view" *ngIf="gallery.length>0;else elseBlock">
	        <div class="col-lg-3 col-md-4 col-sm-6 mb-4" *ngFor="let x of gallery">
	        	
	            <div class="gallery-box">
					<img src="{{'/src/assets/uploads/'+x.gallery_image}}" class="img-fluid">

					<div class="bg-light px-3 py-2 d-flex" style="justify-content:space-between; align-items: center;">
						<div>
							<h6 class="mb-0">{{x.title}}</h6>
							<p class="mb-0"><small>{{x.created_date | date}}</small></p>
						</div>
						
						<ul class="icon list-inline mb-0 text-right">
							<li class="list-inline-item m-1"><a href="javascript:void(0)" class="btn btn-primary btn-sm" routerLink="/admin/artwork/{{x.gallery_id}}"><i class="fa fa-eye"></i></a></li>
							<li class="list-inline-item m-1"><a href="javascript:void(0)" class="btn btn-danger text-white btn-sm" (click)="deleteGallery(x.gallery_id)"><i class="fa fa-trash"></i></a></li>
						</ul>
					</div>
	            </div>
	        </div>
	    </div>
		<ng-template class="row gallery-view" #elseBlock>
		<h4 style="text-align: center;padding: 50px;border: 1px solid #2d2c2c;" class="noData">
			<img src="/assets/img/loading.gif" height="80" width="80">
			Loading...
		</h4>
		</ng-template>
	    

	</div>
</main>
