<style>
    .dataTables_empty {
      display: none !important;
    }
    .modal-dialog .modal-content{
      height: auto !important;
    }
  .invalid-feedback{display: block;}
  span.frontCanvas, span.backCanvas{
    border: 1px dotted;
    position: absolute;
    left: 46px;
    top: 28px;
  }
  .img-box {
    width: 700px;
    margin: 0 auto;
    height: 84.5vh;
    /* border: #000 solid 1px; */
    overflow: hidden;
    position: relative;
}
.img-box img {
    width: 100% !important;
}
mat-slider {
  width: 200px;
}
::ng-deep .mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 6px;
  border-radius: 10px;
  background-color: #3f51b5 !important;
}
::ng-deep .mat-slider-thumb {
  background-color: #3f51b5 !important;
  border: none !important;
  box-shadow: 0px 0px 15px #000;
  outline: 5px solid #fff;
}

::ng-deep .mat-slider-track-fill {
  background-color: #3f51b5 !important;
}

::ng-deep .mat-slider-track-fill,
::ng-deep .mat-slider-wrapper,
::ng-deep .mat-slider-track-wrapper,
::ng-deep .mat-slider-track-background {
  height: 10px !important;
  border-radius: 10px;
}
  /* .productEditModal .modal-dialog {
    max-width: 100% !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    } */
</style>
  <!-- Begin page content -->
  <main role="main" class="main-wrapper" id="main">
      <div class="container-fluid table-page text-center">
        <div class="row ">
          <div class="col-lg-4  text-left">
            <div class="form-row">
              <div class="form-group col-md-6">
                <a class="btn btn-primary btn-event btn-lg " routerLink='/admin/create-product'>Create New Product</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
              &nbsp;
          </div>
  
          <div class="col-lg-12">
            <div class="bg-white p-4 rounded text-left box-shadow-md table-responsive">
              <button class="btn btn-primary" (click)="printPage()">print</button>
              <a href="{{url+'export-product'}}" class="btn btn-primary" style="margin-left: 15px;">Excel</a>
              <br/><br/>
              <table id="tablePreview" datatable [dtOptions]="dtOptions"  class="table small table-hover row-border hover">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Size</th>
                      <th>color</th>
                      <!-- <th>Front Canvas (in px)</th>
                      <th>Back Canvas (in px)</th> -->
                      <th>Preview</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  
                  <tbody *ngIf="products?.length">
                   <tr *ngFor="let product of products; let i=index; let last = last">
                      <td>{{product.name? product.name:''}}</td>
                      <td>{{product.type? product.type:''}}</td>
                      <td>
                        <span *ngFor="let clr of product.size | keyvalue">
                          {{(clr.value? clr.value:'')+' '}}
                        </span>
                        {{(product.other_size? product.other_size:'')}}
                      </td>
                      <td>
                        <span class="px-2 w-10 mr-1" *ngFor="let clr of product.color | keyvalue" [ngStyle]="{'background-color':clr.value}">
                        </span>
                      </td>
                      <!-- <td>{{product.canvas_front_width+'x'+product.canvas_front_height}}</td>
                      <td>{{product.canvas_back_width+'x'+product.canvas_back_height}}</td> -->
                      <td>
                        <img src="{{appurl+product.frontview_file}}" width="50px" height="50px">
                        <!--<img src="{{appurl+product.backview_file}}" width="50px" height="50px">-->
                      </td>
                      <td class="">
                        <span class="">
                            <a href="javascript:void(0)" title="View/Edit Product" class="btn btn-primary btn-sm m-1" (click)="open(mymodal,product.product_id)"> 
                              <i class="fa fa-eye"></i>
                         </a>
                        </span>
                        <span class="" *ngIf="checkAuth()!=''">
                          <a href="javascript:void(0)" title="Remove Product" class="btn btn-danger btn-sm m-1" (click)="deleteProduct(product.product_id)">
                            <!-- <img src="assets/img/icons/delet.png" alt=""> -->
                            <i class="fa fa-trash text-white"></i>
                          </a>
                        </span>   
                      </td>
                  
                    </tr>
                    
                    <tr *ngIf="products?.length == 0">
                      <td colspan="8" class="no-data-available">No data!</td>
                    </tr>
  
                  </tbody>
                </table>
            </div>
            
            <ng-template #mymodal let-modal style="max-width: 1000px;">
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Product</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form class="w-100" enctype="multipart/form-data" [formGroup]="productForm" (ngSubmit)="updateProduct()">
              <div class="modal-body">
                <h4 class="text-dark text-center"><strong>{{product.name? product.name:''}}</strong></h4>
                
                <div class="row pb-5">
                <div class="col-lg-4 col-md-4">
                    <div class="row">
                      
                      <div class="col-12">
                        <div class="form-group">
                          <label>Product Name</label>
                          <input type="text" class="form-control" name="" placeholder="Enter product name" formControlName="product_name" name="product_name" [ngClass]="{ 'is-invalid': submitted && f.product_name.errors }">
                          <div *ngIf="submitted && f.product_name.errors" class="invalid-feedback">
                            <div *ngIf="f.product_name.errors.required">Product Name is required</div>
                            </div>
                        </div>  
                      </div>
        
                    <div class="col-12">
                        <div class="form-group">
                            <label>Product Type</label>
                            <select class="form-control" formControlName="product_type" name="product_type" [ngClass]="{ 'is-invalid': submitted && f.product_type.errors }">
                                <option value="">Choose product type</option>
                                <option *ngFor="let x of productTypes" value="{{x.type_id}}">{{x.product}}</option>
                            </select>
                            <div *ngIf="submitted && f.product_type.errors" class="invalid-feedback">
                                <div *ngIf="f.product_type.errors.required">Product Type is required</div>
                            </div>
                        </div>  
                    </div>
        
                    <div class="col-12 form-group" formGroupName="product_size">
                        <label class="d-block">Choose size of your product</label>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox5" value="S" name="size[]" formControlName="S">
                          <label class="form-check-label" for="inlineCheckbox5">S</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox4" value="M" name="size[]" formControlName="M">
                          <label class="form-check-label" for="inlineCheckbox4">M</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="L" name="size[]" formControlName="L">
                          <label class="form-check-label" for="inlineCheckbox3">L</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="XL" name="size[]" formControlName="XL">
                          <label class="form-check-label" for="inlineCheckbox2">XL</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="XXL" name="size[]" formControlName="XXL">
                            <label class="form-check-label" for="inlineCheckbox1">XXL</label>
                        </div>
                    </div>    
                    
                    <div class="col-12">
                      <div class="form-group">
                          <label>Other Size</label>
                          <input type="text" class="form-control" name="other_size" placeholder="Size" formControlName="other_size">
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group inputDnD">
                        <label>Product Image</label>
                        <input type="file" (change)="onFileSelect($event,'productimg',1)" class="form-control-file" id="productimg" name="productimg" accept="image/*"  data-title="Upload Image">
                      </div>
                      <span class="app-preview" *ngIf="(product.productview_file && product.productview_file!='') ">
                        <img src="{{appurl+product.productview_file}}" width="100px" height="100px" id="productimg-preview">
                        <label>Preview</label>
                    </span>
                    </div>


                    <!-- <div class="form-group inputDnD mb-0">
                      <label>Logo</label>
                      <input type="file" class="form-control-file" id="inputFile2" accept="image/*" (change)="onFileSelect($event,'thankyoulogo')" data-title="Drag your image/Video here eg. PNG, JPG, MPEG4">
                      <span class="app-preview" *ngIf="(config.thankyou && config.thankyou.logo_file_path!='') || activeContent.thankyou">
                          <img [src]="fileurl+''+(config.thankyou && config.thankyou.logo_file_path!=''? config.thankyou.logo_file_path:'')" class="thankyoulogo-preview" height="100" width="100">
                          <label>Preview</label>
                      </span>
                  </div> -->
        
                      <!-- <div class="col-12">
                          <label>Canvas Size Front (in Pixel)</label>              
                      </div> -->
                      <!-- <div class="col-6">
                        <div class="form-group">
                          <input type="no" class="form-control" name="canvas_front_width" id="canvas_front_width" (change)="frontCanvasChange()" formControlName="canvas_front_width" placeholder="Width" [ngClass]="{ 'is-invalid': submitted && f.canvas_front_width.errors }">
                          <div *ngIf="submitted && f.canvas_front_width.errors" class="invalid-feedback">
                            <div *ngIf="f.canvas_front_width.errors.required">Canvas front width is required</div>
                          </div>
                        </div>  
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="no" class="form-control" name="canvas_front_height" id="canvas_front_height" (change)="frontCanvasChange()" formControlName="canvas_front_height" placeholder="Height" [ngClass]="{ 'is-invalid': submitted && f.canvas_front_height.errors }">
                          <div *ngIf="submitted && f.canvas_front_height.errors" class="invalid-feedback">
                            <div *ngIf="f.canvas_front_height.errors.required">Canvas front height is required</div>
                          </div>
                        </div>  
                      </div> -->
        
        
                      <!-- <div class="col-12">
                          <label>Canvas Size Back (in Pixel)</label>              
                      </div> -->
                      <!-- <div class="col-6">
                        <div class="form-group">
                          <input type="no" class="form-control" name="canvas_back_width" formControlName="canvas_back_width" id="canvas_back_width" (change)="backCanvasChange()" placeholder="Width" [ngClass]="{ 'is-invalid': submitted && f.canvas_back_width.errors }">
                          <div *ngIf="submitted && f.canvas_back_width.errors" class="invalid-feedback">
                            <div *ngIf="f.canvas_back_width.errors.required">canvas back width is required</div>
                          </div>
                        </div>  
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <input type="no" class="form-control" name="canvas_back_height" formControlName="canvas_back_height" id="canvas_back_height" (change)="backCanvasChange()" placeholder="Height" [ngClass]="{ 'is-invalid': submitted && f.canvas_back_height.errors }">
                          <div *ngIf="submitted && f.canvas_back_height.errors" class="invalid-feedback">
                            <div *ngIf="f.canvas_back_height.errors.required">canvas back height is required</div>
                          </div>
                        </div>  
                      </div> -->
                    </div>
                </div> 
        
                <div class="col-lg-8 col-md-8" formArrayName="product_color">

                  <div class="row">
                    <div class="col-12">
                      <label>Product Color & Images</label>
                    </div>
                    <div class="col-md-12 pull-right pb-2" style="padding-top: 3px;">
                      <a href="javascript:void(0)" (click)="addColor()" class=" badge badge-success p-2"
                         style="width: 200px; font-size: 12px; color: #fff;" title="Add Product Type">
                        <i class="fa fa-plus" aria-hidden="true"></i> Add Color And Product Image
                      </a> &nbsp;&nbsp;
                    </div>
                    <div class="col-md-12 form-group" *ngFor="let quantity of product_color().controls; let i=index" [formGroupName]="i">
                      <div class="card p-3" style="position:relative;">
                        <div class="row" style="position:absolute; top:-15px; right:0px; background:#f5f5f5;border-radius: 50%;z-index: 999;">
                          <a href="javascript:void(0)" (click)="removeColor(i)" class="btn btn-default  btn-sm" title="Delete Product Type">
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </a>
                        </div>
                        <div class="row">
                          <div class="col-md-6 form-group d-flex">
                            <input type="hidden" class="form-control" placeholder="Choose Color" formControlName="id" name="id">
                            <input type="color" class="form-control" placeholder="Choose Color" formControlName="color" name="color">
                          </div>
                          <div class="col-md-6 form-group d-flex">
                            <input type="text" class="form-control" placeholder="Color Name" formControlName="color_name" name="color_name">
                          </div>
                        </div>
                        <!-- front canvas  -->
                        <div class="row frontrow{{i}}">
                          <input type="hidden" class="form-control" formControlName="canvas_front_left" name="canvas_front_left" class="canvas_front_left{{i}}">
                          <input type="hidden" class="form-control" formControlName="canvas_front_top" name="canvas_front_top" class="canvas_front_top{{i}}">
                          <div class="col-md-6 form-group d-flex">
                            <input type="no" class="form-control" name="front_width" formControlName="front_width"  placeholder="Front canvas width"  (change)="frontCanvasChange(i)" id="front_width{{i}}">
                            
                            <!-- <input id="front_width_real{{i}}" min="1" max="20" value='20' step="1" (change)="setZoom('front_width_real',i)" type="range"/> -->
                            <!-- <mat-slider  horizontal thumbLabel  color="warn" style="z-index: 222;position:absolute;bottom: 50px; right:25px; " min="1" max="10" step="1" value="1" (input)="setZoom($event,i)" ></mat-slider> -->
                          </div>
                          <div class="col-md-6 form-group d-flex">

                            <input type="no" class="form-control" name="front_height" formControlName="front_height"  placeholder="Front canvas height"  (change)="frontCanvasChange(i)" id="front_height{{i}}" >
                            <input type="hidden" class="form-control" formControlName="resize_front_height" name="resize_front_height" class="resize_front_height{{i}}">
                            <input type="hidden" class="form-control" formControlName="resize_front_width" name="resize_front_width" class="resize_front_width{{i}}">
                            <input type="hidden" class="form-control" formControlName="front_transform" name="front_transform" class="front_transform{{i}}">
  
                            <!-- <div *ngIf="submitted && f.canvas_back_height.errors" class="invalid-feedback">
                              <div *ngIf="f.canvas_back_height.errors.required">canvas back height is required</div>
                            </div> -->

                          </div>
                        </div>
                         <!-- Back canvas -->
                        <div class="row backrow{{i}}">
                          <input type="hidden" class="form-control" formControlName="canvas_back_left" name="canvas_back_left" class="canvas_back_left{{i}}">
                          <input type="hidden" class="form-control" formControlName="canvas_back_top" name="canvas_back_top" class="canvas_back_top{{i}}">
                          <div class="col-md-6 form-group d-flex">
                            <input type="no" class="form-control" name="back_width" formControlName="back_width"  placeholder="Back canvas width"  (change)="backCanvasChange(i)" id="back_width{{i}}">
                            <!-- <input id="back_width_real{{i}}" min="1" max="10" value='10' step="1" (change)="setZoomBack('back_width_real',i)" type="range"/> -->
                            <!-- <mat-slider  horizontal thumbLabel  color="warn" style="z-index: 222;position:absolute;bottom: 50px; right:25px; " min="1" max="10" step="1" value="1" (input)="setZoomBack($event,i)" ></mat-slider> -->
                          </div>
                          <div class="col-md-6 form-group d-flex">

                            <input type="no" class="form-control" name="back_height" formControlName="back_height"  placeholder="Back canvas height"  (change)="backCanvasChange(i)" id="back_height{{i}}" >
                            <input type="hidden" class="form-control" formControlName="resize_back_height" name="resize_back_height" class="resize_back_height{{i}}">
                          <input type="hidden" class="form-control" formControlName="resize_back_width" name="resize_back_width" class="resize_back_width{{i}}">
                          <input type="hidden" class="form-control" formControlName="back_transform" name="back_transform" class="back_transform{{i}}">
                            
                            <!-- [ngClass]="{ 'is-invalid': submitted && f.canvas_back_height.errors }" -->
                            <!-- <div *ngIf="submitted && f.canvas_back_height.errors" class="invalid-feedback">
                              <div *ngIf="f.canvas_back_height.errors.required">canvas back height is required</div>
                            </div> -->

                          </div>
                        </div>
                       
                        <!-- images -->
                        <div class="row">
                          <div class="col-12">
                            <div class="matfront">
                              <mat-slider  horizontal  style="z-index: 1;top: 0px; right:26px; position: absolute;" min="1" max="20" step="1" value="1" (input)="setZoom($event,i)" class="row frontrowslider{{i}}" ></mat-slider>
                            </div>
                            <div class="inputDnD">
                              <div class="in1">
                                <input type="file" style="display:none;width: 100%;" id="frontview{{i}}" name="frontview" formControlName="frontview" accept="image/*" (change)="onFileSelect($event,'frontview',i)">
                                <span (click)="fileClick('front',i)" class="frontFile{{i}}" style="cursor:pointer; border:dashed 1px grey;display:block; padding:10px;text-align:center;width:100%;">
                                  
                                  <i class="fa fa-upload"></i> Front Image
                                </span>
                              </div>
                              <div class="in2">
                                <span class="frontSpan{{i}}" style="display:block;text-align: center;border: dashed 1px grey;"></span>
                              </div>
                              
                              
                            </div>
                          </div>
                          <div class="col-12 text-center">
                            <div class="matback">
                              <mat-slider  horizontal style="z-index: 1;top: 0px; right:26px; position: absolute;" min="1" max="20" step="1" value="1" (input)="setZoomBack($event,i)" class="row backrowslider{{i}}"  ></mat-slider>
                            </div>
                            <div class="inputDnD">
                              <div class="in1">
                                <input type="file" style="display:none" id="backview{{i}}" formControlName="backview" accept="image/*" (change)="onFileSelect($event,'backview',i)" data-title="Back Image Upload">
                                <span (click)="fileClick('back',i)" style="cursor:pointer; border:dashed 1px grey;display:block; padding:10px;text-align:center">
                                  
                                  <i class="fa fa-upload"></i> Back Image
                                </span>
                              </div>
                              <div class="in2">
                                <span class="backSpan{{i}}" style="display:block;text-align: center;border: dashed 1px grey;"></span>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!--<div class="row">
      <div class="col-6">
        <div class="form-group inputDnD">
          <label>Upload Front Image</label>
            <input type="file" class="form-control-file" id="frontview" formControlName="frontview" [ngClass]="{ 'is-invalid': submitted && f.frontview.errors }" accept="image/*" (change)="onFileSelect($event,'frontview',1)" data-title="Front Image Upload">
        </div>-->
                  <!-- <div *ngIf="submitted && f.frontview.errors" class="invalid-feedback">
    <div *ngIf="f.frontview.errors.required">Front image is required</div>
  </div> -->
                  <!--</div>
  <div class="col-6">
    <div class="form-group inputDnD">
         <label>Upload Back Image</label>
        <input type="file" class="form-control-file" id="backview" formControlName="backview" [ngClass]="{ 'is-invalid': submitted && f.backview.errors }" accept="image/*" (change)="onFileSelect($event,'backview',1)" data-title="Back Image Upload">
    </div>-->
                  <!-- <div *ngIf="submitted && f.backview.errors" class="invalid-feedback">
    <div *ngIf="f.backview.errors.required">Back image is required</div>
  </div> -->
                  <!--</div>
  </div>-->
                  <!--<div class="row">
      <div class="col-6 my-auto text-center">
          <img src="assets/img/file-preview.jpg" width="100%" height="auto" class="img-fluid frontblock">
      </div>
      <div class="col-6 my-auto text-center">
          <img src="assets/img/file-preview.jpg"  width="100%" height="auto" class="img-fluid backblock">
      </div>
  </div>-->



                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-outline-dark"><i class="fa fa-pencil"></i> Update </button>
              </div>
            </form>
          </ng-template>
  
          </div>

        </div>
      </div>
    </main>
