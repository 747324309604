<main role="main" *ngIf='pageLoaded'>
  <div class="row">
    <img *ngIf="product.bg_image!='' && (product.bg_file_extention=='jpg' || product.bg_file_extention=='jpeg' || product.bg_file_extention=='gif' || product.bg_file_extention=='png'); else noneBg" [src]="product.bg_image" class="full-video">
    <ng-template #noneBg>
      <div class="full-video" style="background-color: #2D2C2C;"></div>
    </ng-template>
  </div>
  <video autoplay playsinline muted loop id="myVideo" width="100%" style="height: 100% !important;overflow-y: none !important;" oncanplay="this.play()" onloadedmetadata="this.muted = true" *ngIf="product.bg_file_extention=='mp4' || product.bg_file_extention=='avi' || product.bg_file_extention=='mpeg4'">
    <source [src]="product.bg_image" type="video/mp4">
  </video>

  <div class="container choose-product text-md-center">
      <div class="row d-flex justify-content-center" style="height: 100vh;">
        <div   class="col-3 my-auto">
          <h2 class="mt-3 text-center text-white productHeading"><strong>{{product.productApp.header_size_text}}</strong></h2>
          <div *ngFor="let sz of product.sizes">
            <a href="javascript:void(0)" routerLink="/product-color/{{evid}}/{{user}}/{{client}}/{{productID}}/{{sz}}" class="btn btn-primary btn-lg btn-block productlargebtn mb-2">{{sz}}</a>
          </div>

          
        </div>
       
      </div>      
    </div>

</main>
