 
  <main role="main" id="main" class="main-wrapper">
    <div class="container-fluid table-page text-center">
      <div class="row">
        <div class="col-lg-4  text-left">
          <div class="form-row">
            <!-- <div class="form-group col-md-6">
              <input type="name" class="form-control" id="inputfirst" placeholder="Find user">
            </div> -->
            <div class="form-group col-md-6">
              <a href="javascript:void(0)" class="btn btn-primary btn-event btn-lg " routerLink="/admin/create-user">Add New User</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4">

        </div>
        <!-- <div class="col-lg-4 text-right">
          <div class="form-row">
            <div class="form-group col-md-6">
              <li class="nav-item dropdown" style="list-style-type: none;">
                <a class="nav-link dropdown-toggle" id="filterDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="assets/img/icons/filter.png" class="user-circle" width="20px" alt=""></a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="filterDropdown">
                    <a class="dropdown-item small" href="#"><span class="ml-2"><input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></span> User Type</a>
                    <a class="dropdown-item small" href="#"><span class="ml-2"><input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></span> Status</a>
                    <a class="dropdown-item small" href="#"> <span class="ml-2"><input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked></span>Location</a>
                </div>
            </li>
            </div>
            <div class="form-group col-md-6">
              <select id="inputState" class="form-control">
                <option selected="">Export as PDF</option>
                <option>Export as Excel</option>
              </select>
            </div>
          </div>
        </div> -->

        <div class="col-lg-12">
          <div class="bg-white p-4 rounded text-left box-shadow-md table-responsive">
            <table id="tablePreview" datatable [dtOptions]="dtOptions" class="table small table-hover">
              <!--Table head-->
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>User type</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <!--Table head-->
                <!--Table body-->
                <tbody *ngIf="users?.length">
                  <tr *ngFor="let x of users; let i=index; let last = last">
                    <td>{{x.name}}</td>
                    <td [ngSwitch]="x.user_type">
                        <span *ngSwitchCase="1">Admin</span>
                        <span *ngSwitchCase="2">Executive</span>
                        <span *ngSwitchCase="3">Manager</span>
                        <span *ngSwitchCase="4">Staff</span>
                    </td>
                    <td>{{x.email}}</td>
                    <td>{{x.contact}}</td>
                    <td>{{x.location}}</td>
                    <td>
                        <span *ngIf='x.is_active==1'>Active</span>
                        <span *ngIf='x.is_active==0'>InActive</span>
                    </td>
                    <td class="">
                      <a href="javascript:void(0)" (click)="open(mymodal,x.id)" class="btn btn-primary btn-sm m-1">
                        <i class="fa fa-eye"></i>
                      </a>
                      <button *ngIf="checkAuth()!=''" class="btn btn-danger btn-sm m-1" (click)="deleteUser(x.id)">
                        <i class="fa fa-trash text-white"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <!--Table body-->
              </table>

              <ng-template #mymodal let-modal style="max-width: 1000px;">
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">User</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <form class="w-100" [formGroup]='userForm' (ngSubmit)="createUser()">
                <div class="modal-body">
                  <h4 class="text-dark text-center"><strong>{{user.name}}</strong></h4>
    
                  <div class="row">
                    <div class="col-12 form-group">
                      <label class="d-block">User Type</label>
                      <select class="form-control" formControlName='user_type' [ngClass]="{ 'is-invalid': submitted && f.user_type.errors }">
                        <option value="">Select user type</option>
                        <option value="1">Admin (Full Access)</option>
                        <option value="2">Executive (Application Access)</option>
                        <option value="3">Manager (Event Manager Access Only)</option>
                        <option value="4">Staff</option>
                                    </select>
                                    <div *ngIf="submitted && f.user_type.errors" class="text-danger">
                                        <div *ngIf="f.user_type.errors.required">User Type is required</div>
                                    </div>
                    </div>	
                  </div>	
        
                  <div class="row">
                    <div class="col-12 form-group">
                      <label>Name</label>
                      <input type="text" class="form-control" formControlName='name' placeholder="Enter Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                      <div *ngIf="submitted && f.name.errors" class="text-danger">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                      </div>
                    </div>	
                  </div>	
        
                  <div class="row">
                    <div class="col-12 form-group">
                      <label>User Name</label>
                      <input type="text" class="form-control" formControlName='user_name' placeholder="Enter Username" [ngClass]="{ 'is-invalid': submitted && f.user_name.errors }">
                      <div *ngIf="submitted && f.user_name.errors" class="text-danger">
                        <div *ngIf="f.user_name.errors.required">User Name is required</div>
                      </div>
                    </div>
                                
                    <!-- <div class="col-12 form-group">
                      <label>Password</label>
                      <input type="password" class="form-control" formControlName='password' placeholder="Enter Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                      <div *ngIf="submitted && f.password.errors" class="text-danger">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                      </div>
                    </div> -->
                  </div>
                  <div class="row">	
                    <div class="col-12 form-group">
                      <label>Email</label>
                      <input type="mail" class="form-control" formControlName='email' placeholder="Enter mail id" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                      <div *ngIf="submitted && f.email.errors" class="text-danger">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Please enter valid email address</div>
                      </div>
                    </div>
                  </div>		
        
                  <div class="row">
                    <div class="col-6 form-group">
                      <label>Contact</label>
                                    <input type="text" pattern="\d*" class="form-control" formControlName='contact' max="12" placeholder="(+1) 4585879" [ngClass]="{ 'is-invalid': submitted && f.contact.errors }">
                                    <div *ngIf="submitted && f.contact.errors" class="text-danger">
                                        <div *ngIf="f.contact.errors.required">Contact is required</div>
                                        <!-- <div *ngIf="f.contact.errors.min">Contact should be minimum 10 digit long</div> -->
                        <div *ngIf="f.contact.errors.pattern">Contact should be numeric</div>
                                        <div *ngIf="f.contact.errors.maxlength">Contact should not be more than 12 digit long</div>
                                    </div>
                    </div>	
                    <div class="col-6 form-group">
                      <label>Location</label>
                                    <input type="text" class="form-control" formControlName='location' placeholder="Los Angeles" [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                                    <div *ngIf="submitted && f.location.errors" class="text-danger">
                                        <div *ngIf="f.location.errors.required">Location is required</div>
                                    </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 form-group">
                      <label class="d-block">Status</label>
                      <select class="form-control" formControlName='status' [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                        <option value="">Select status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <div *ngIf="submitted && f.status.errors" class="text-danger">
                        <div *ngIf="f.status.errors.required">Status is required</div>
                    </div>
                    </div>
                  </div>
    
                </div>
                <div class="modal-footer">
                  <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')"><i class="fa fa-pencil"></i> </button> -->
                  <button type="submit" class="btn btn-outline-dark"><i class="fa fa-pencil"></i> Update </button>
                </div>
              </form>
            </ng-template>

          </div>
        </div>

      </div>
    </div>
  </main>

  <!-- Modal -->
  <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
        <div class="modal-body text-center success-message">
            <div class="py-5">
            <div class="mb-3"><i class="fa fa-trash-o"></i></div>
            <h4 class="mb-3">Are you sure you want to delete User</h4>  
            <button class="btn btn-primary" data-dismiss="modal">
            Cancel
            </button>
            <button class="btn btn-dark" data-dismiss="modal">
            Delete
            </button>
        <p class="mt-3 mb-0">By clicking delete button your event will be deleted permanently</p> 
        </div>  
        </div>
        
    </div>
    </div>
</div>