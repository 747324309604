<main role="main" id="main" class="main-wrapper">
    <form name="galleryForm" [formGroup]="galleryForm" (ngSubmit)="assignGallery()">
        <div class="container">
            <div class="row gallery-view">
                <div class="col-md-4 col-sm-6" *ngFor="let x of gallery;let i = index">
                    <div class="form-check-inline mb-2" style="padding-left:25px;">
                        <label class="form-check-label checkbox-info" for="gal{{i}}" style="opacity:1">
                            <input type="checkbox" class="form-check-input" name="gallery" formControlName="galleries" id="gal{{i}}" (change)="pushGal(x.gallery_id,$event.target,i)">
                            {{x.title}}

                            <div class="box">
                                <img src="{{'/src/assets/uploads/'+x.gallery_image}}" class="img-fluid">
                                <!-- <ul class="icon">
                                    <li><a href="javascript:void(0)" routerLink="/admin/artwork/{{x.gallery_id}}"><i class="fa fa-eye"></i></a></li>
                                    <li><a href="javascript:void(0)" (click)="deleteGallery(x.gallery_id)"><i class="fa fa-trash"></i></a></li>
                                </ul> -->
                            </div>

                        </label>
                    </div>
                    
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 text-center">
                    <button type="submit" class="btn btn-primary col-2 mr-2">Assign Gallery</button>
                    <a href="javascript:void(0)" class="btn btn-outline-primary col-2">Back</a>
                </div>
            </div>

        </div>
    </form>
</main>
