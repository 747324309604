<!-- <header *ngIf="hasHeader">
    <nav class="navbar navbar-expand-md navbar-dark  bg-dark">
    <a href="index.html" class="logo mr-auto"><img src="/assets/img/FI-Logo-Color-White.png" alt="logo" class=" logo img-fluid"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
            </li>
        </ul>
        </div>
    </nav>
</header> -->

<router-outlet></router-outlet>


    <!-- footer -->
    <!-- <footer *ngIf="hasHeader" class="footer mt-auto py-3 bg-dark">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-4 text-md-left"> 
              <p class="small"> Copyright &copy; Family Printing. Developed By Nectar Infotel PVT. LTD. </p> 
            </div>
            <div class="col-lg-4  col-md-4 text-md-center">
          </div>
            <div class="col-lg-4  col-md-4 text-md-right">
             <p class="small"><a href="#">Terms of Use &nbsp;</a>|<a href="#">&nbsp; Privacy Policy </a></p>
            </div>
          </div>
        </div>
      </footer> -->
      
        <!-- Vendor JS Files -->
        <!-- <script src="assets/vendor/jquery/jquery.min.js"></script> -->
        <!-- <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script> -->
        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
      
      <!-- Template Main JS File -->
      
      