<style>
  .prod-gallery:hover {
   /* box-shadow: 0px 1px 5px 5px #98daf1;*/
    transform: scale(1.1);
    cursor:pointer;
  }
  .prod-gallery {
    transition: transform .5s ease;
    cursor: pointer;
  }
</style>
<main role="main" *ngIf='pageLoaded'>
  <div class="row">
    <img *ngIf="product.bg_image!='' && (product.bg_file_extention=='jpg' || product.bg_file_extention=='jpeg' || product.bg_file_extention=='gif' || product.bg_file_extention=='png'); else noneBg" [src]="product.bg_image" class="full-video">
    <ng-template #noneBg>
      <div class="full-video" style="background-color: #2D2C2C;"></div>
    </ng-template>
  </div>
  <video autoplay playsinline muted loop id="myVideo" width="100%" style="height: 100% !important;overflow-y: none !important;" oncanplay="this.play()" onloadedmetadata="this.muted = true" *ngIf="product.bg_file_extention=='mp4' || product.bg_file_extention=='avi' || product.bg_file_extention=='mpeg4'">
    <source [src]="product.bg_image" type="video/mp4">
  </video>

  <div id="outer" style="z-index: 999999999999;">
    <div id="table-container" style="width:100%;">
      <div id="table-cell">


        <h2 class="mt-3 text-center text-white productHeading"><strong>{{product.header_text}}</strong></h2>
        <div class="container choose-product text-md-center">

          <div *ngFor="let y of getSplitedProd() | keyvalue; let j=index;" class="row d-flex custom-product-select justify-content-center">
            <div *ngFor="let x of getProductArr(y.key); let i=index;" class="col-4 custom-border mb-3">
              <div class="box bg-white m-2 prod-gallery" style="cursor:pointer;" routerLink="/product-size/{{evid}}/{{user}}/{{client}}/{{x.product_id}}">
                <label for="prod_{{x.product_id}}">
                  <img  [src]="appurl+''+(x.productview_file ? x.productview_file : x.frontview_file)" class="img-fluid" alt="">
                  <div class="form-check form-check-inline d-block">
                    {{x.name}}
                  </div>
                </label>
              </div>
            </div>
          </div>

          

          <!-- <div class="text-md-center next-btn mt-4 mb-4">
    <a href="javascript:void(0)" class="btn btn-primary btn-lg " (click)="printNow()">NEXT</a>
  </div>-->
        </div>

      </div>
    </div>
  </div>  
</main>
