<style>
    .invalid-feedback{display:block}
</style>
<main role="main" id="main" class="main-wrapper">
    <div class="container-fluid">
        <div class="col-lg-12 p-4 offset-md-12">
            <form class="w-100" enctype="multipart/form-data" [formGroup]="artworkForm" (ngSubmit)="create_clipart()">

                <div class="row">
                    <div class="col-12">
                        <!-- <h4 class="text-center"><strong>Add Artwork</strong></h4> -->
                    </div>
                    <!-- <div class="col-12 form-group">
                        <label>Collection Name</label>
                        <input type="text" class="form-control" name="title" placeholder="Select Collection Name" formControlName="title">
                    </div> -->
                </div>
                <div class="row">	
                    <div class="col-12">
                        <div class="form-group inputDnD" style="height: 100px;">
                        <label>Upload Image</label>
                        <input type="file" class="form-control-file" id="inputFile" name="bgimage[]" formControlName="bgimage" [ngClass]="{ 'is-invalid': submitted && f.bgimage.errors }" multiple accept="image/*" (change)="onFileSelect($event)" data-title="Drag your image here eg. PNG">
                        </div>
                       
                        <div *ngIf="submitted && f.bgimage.errors" class="invalid-feedback">
                            <div *ngIf="f.bgimage.errors.required">Artwork Image is required</div>
                        </div>
                    </div>
                </div>		

                <div>
                    <img *ngFor="let url of urls" [src]="url" class="rounded mb-3" width="180" style="padding-left:50px">
                </div>

                <div *ngIf="loader">
                    <h4 style="text-align: center;padding: 50px;" class="noData">
                        <img src="/assets/img/loading.gif" height="80" width="80">
                        Uploading Artworks...
                    </h4>
                </div>

                <div class="row">
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-primary ">Upload</button>
                    </div>	
                </div>		
            </form>
        </div>      
    </div>
</main>